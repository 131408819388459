// var settings
var bW = document.documentElement.clientWidth;
var bH = document.documentElement.clientHeight;
var scrollTop = $(window).scrollTop();;
$(window).on('resize', function () {
  bW = document.documentElement.clientWidth;
  bH = document.documentElement.clientHeight;
})
var bWsm = 768; // sm mixin
var bWsp = 376; // sp mixin




// body show
document.getElementById("body").classList.add("show");